"use client";

import { useEffect } from "react";
import { usePathname } from "next/navigation";
import Bugsnag from "@bugsnag/js";

import ErrorBlock from "@/components/molecules/ErrorBlock";
import { fallbackLng } from "@/i18n/settings";

interface ErrorPageProps {
  error: Error;
  reset: () => void;
}

const ErrorPage = ({ error, reset }: ErrorPageProps) => {
  useEffect(() => {
    if (process.env.NEXT_PUBLIC_BUGSNAG_API_KEY) {
      Bugsnag.notify(error);
    }
    console.error(error);
  }, [error]);

  const pathname = usePathname();
  const locale = pathname?.split("/").filter(Boolean)?.[0] ?? fallbackLng;

  return (
    <main className="grid place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8">
      <ErrorBlock locale={locale} error={error} reset={reset} />
    </main>
  );
};

export default ErrorPage;
